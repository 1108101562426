import { Link } from "@/components/Link/Link.component";
import { clsx } from "clsx";
type SectionLinkProps = {
  readonly className?: string;
  readonly backgroundColor?: "primary" | "secondary";
  readonly children: JSX.Element | string;
  readonly targetUrl?: null | string;
  readonly position?: string;
};
export function SectionLink({
  children,
  className,
  position,
  targetUrl = null
}: SectionLinkProps) {
  return <div className={clsx(className, "max-w-full select-none text-xs font-bold uppercase")} data-sentry-component="SectionLink" data-sentry-source-file="SectionLink.component.tsx">
      {targetUrl !== null ? <Link className="w-fit hover:underline" href={targetUrl} position={position ?? "s-a"}>
          {children}
        </Link> : children}
    </div>;
}